import Axios from "axios";
import url from "./api";

export default {
  namespaced: true,
  state: () => ({
    data: {},
    detail: {},
    params: {},
  }),
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_PARAMS(state, data) {
      state.params = data;
    },
    SET_DETAIL(state, data) {
      state.detail = data;
    },
  },
  getters: {
    data: (state) => state.data,
    params: (state) => state.params,
    detail: (state) => state.detail,
  },
  actions: {
    list({ state, commit }, params) {

      // console.log('params q', params)
      let a = {
        page: params.q != "" ? 1 : state.data.page, limit: state.data.limit
      }

      params = {
        ...a,
        ...state.params,
        ...params,
      };
      console.log('params', params)
      state.data.list = [];
      return Axios.get(url.member_redeem_list, { params })
        .then((res) => res.data)
        .then((res) => {
          delete params.page;
          delete params.limit;
          commit("SET_PARAMS", params);
          if (params.license == "" && params.member_id == "" && params.pmo_code == "" && params.program_ids === null && params.progress_end === null && params.progress_start === null && params.start_date === null && params.end_date === null) {
            commit("SET_DATA", {});
          } else {
            commit("SET_DATA", res.data);
          }
          return res.data;
        });
    },
  },
};
